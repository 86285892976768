import MenuItem from './MenuItem';

const Footer = (props) => {

  const { location, options } = props;
  const { footerColumn1, footerColumn2, footerBottomRow } = options;

  return (
    <footer className={`footer container-fluid`}>
      <div className="footer__inner row">
        <nav className="footer__menu col-xxs-6">
          {
            footerColumn1 &&
            footerColumn1[0] &&
            footerColumn1.map(
              (item, index) => (
                <MenuItem
                  {...props}
                  key={index}
                  item={item}
                  pathname={location.pathname}
                  className="footer__menu__item"
                />
              )
            )
          }
        </nav>
        <nav className="footer__menu col-xxs-6">
          {
            footerColumn2 &&
            footerColumn2[0] &&
            footerColumn2.map(
              (item, index) => (
                <MenuItem
                  {...props}
                  key={index}
                  item={item}
                  pathname={location.pathname}
                  className="footer__menu__item"
                />
              )
            )
          }
        </nav>
        <nav className="footer__menu footer__menu--bottom col-xxs-12">
          {
            footerBottomRow &&
            footerBottomRow[0] &&
            footerBottomRow.map(
              (item, index) => (
                <MenuItem
                  {...props}
                  key={index}
                  item={item}
                  pathname={location.pathname}
                  className="footer__menu__item"
                />
              )
            )
          }
        </nav>
      </div>
    </footer>
  )
}

export default Footer;