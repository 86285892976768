import { AnimatePresence, motion } from "framer-motion";
import { Link } from "react-router-dom";
import Logo from './Logo';
import MenuItem from './MenuItem';

const Header = (props) => {

  const { setSearchIsActive, searchIsActive, location, options, menuIsActive, setMenuIsActive, windowWidth, isInitialised } = props;
  const { menu } = options;

  return (
    <header className={`header${searchIsActive === true || location.pathname === '/search' ? ' search-is-active' : ''}`}>
      <div className="header__inner">
        <Link
          to="/"
          className={`header__link--main${isInitialised === false ? ' initialiser' : ''}`}
        >
          <Logo {...props} />
        </Link>
        <AnimatePresence mode='wait'>
          {
            windowWidth >= 768 && isInitialised === true &&
            <motion.nav
              initial={{ options: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              className="header__menu"
              key="menu"
            >
              {
                menu &&
                menu[0] &&
                menu.map(
                  (item, index) => (
                    <MenuItem
                      {...props}
                      key={index}
                      item={item}
                      pathname={location.pathname}
                      className="header__menu__link"
                    />
                  )
                )
              }
            </motion.nav>
          }
          {
            windowWidth < 768 && isInitialised === true &&
            <motion.button
              initial={{ options: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              className="menu-button button--plain header__menu__link"
              onClick={() => {
                setMenuIsActive(!menuIsActive);
                setSearchIsActive(false);
              }}
                key="button"
            >{menuIsActive === true ? 'Close' : 'Menu'}</motion.button>
          }
        </AnimatePresence>
      </div>
    </header>
  )
}

export default Header;