import parse from 'html-react-parser';

const PageContentRowText = (props) => {

  const { row } = props;
  const { value } = row;

  if (value && value !== '') {
    return (
      <div className="row">
        <div className="col-xxs-12">
          <div className="text__wrapper text__wrapper--full-width">
            {value ? parse(value) : ''}
          </div>
        </div>
      </div>
    );
  } else {
    return null;
  }
}

export default PageContentRowText;