import ArticleThumbnail from './ArticleThumbnail';
import ProductThumbnail from './Product';
import EditionThumbnail from './EditionThumbnail';

const SearchResult = (props) => {

  const { searchResult } = props;

  return (
    <li>
      {
        searchResult.collection === 'articles' &&
        <ArticleThumbnail {...props} article={searchResult} />
      }
      {
        searchResult.collection === 'products' &&
        <ProductThumbnail {...props} article={searchResult} />
      }
      {
        searchResult.collection === 'editions' &&
        <EditionThumbnail {...props} article={searchResult} />
      }
    </li>
  );
}

export default SearchResult;