import { useState, useEffect } from 'react';
import { getImage } from '../utils/cockpit';

const LazyloadImage = (props) => {

  const { image, w, h, isBackgroundImage, desaturate, callback, className, backgroundPositionX, backgroundPositionY } = props;
  const [imageSrcSmall, setImageSrcSmall] = useState('');
  const [imageSrcLarge, setImageSrcLarge] = useState('');
  const [imageIsLoaded, setImageIsLoaded] = useState(false);

  useEffect(() => {

    let img;
    const onImageLoad = () => {
      setImageIsLoaded(true);
    }

    if (image.path) {
      const path = image.path;

      getImage(
        path, 8, 8, 30, (urlSmall) => {
          setImageSrcSmall(urlSmall);
          getImage(
            path,
            1024,
            1024,
            30,
            (imageUrl) => {
              const url = imageUrl;
              setImageSrcLarge(url);
              img = document.createElement('img');
              img.addEventListener('load', onImageLoad);
              img.src = url;
            },
            desaturate ? ['desaturate'] : false
          );
        },
        desaturate ? ['desaturate'] : false
      );
    }

    return () => {
      if (img) {
        img.removeEventListener('load', onImageLoad);
      }
    }
  }, [image.path, w, h, desaturate]);

  useEffect(() => {
    if (imageIsLoaded === true) {
      callback && callback();
    }
  }, [imageIsLoaded, callback]);

  if (image.path && image.path !== '') {
    if (isBackgroundImage === true) {
      return (
        <div
          className={`background-image--lazyload${imageIsLoaded === true ? ' loaded' : ''}${className ? ` ` + className : ''}`}
          style={{
            backgroundImage: `url(${imageIsLoaded === true ? imageSrcLarge : imageSrcSmall})`,

            backgroundPositionX: backgroundPositionX ? backgroundPositionX + '%' : 50 + '%',
            backgroundPositionY: backgroundPositionY ? backgroundPositionY + '%' : 50 + '%'
          }}
        />
      )
    } else {
      return (
        <img
          className={`image--lazyload${imageIsLoaded === true ? ' loaded' : ''}${className ? ` ` + className : ''}`}
          src={imageIsLoaded === true ? imageSrcLarge : imageSrcSmall}
          alt={image.title}
        />
      )
    }
  } else {
    return null
  }
}

export default LazyloadImage;