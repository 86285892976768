import { useState, useEffect } from 'react';
import Product from '../Product';

const PageContentRowFeaturedProduct = (props) => {

  const { row, products } = props;
  const { value } = row;
  const [product, setProduct] = useState({});

  useEffect(() => {
    if (value && value._id) {
      for (let productObject of products) {
        if (productObject._id === value._id) {
          setProduct(productObject);
        }
      }
    }
  }, [value, products]);

  if (product._id) {
    return (
      <ul className="row list--unstyled">
        <Product product={product} {...props} />
      </ul>
    );
  } else {
    return null;
  }
}

export default PageContentRowFeaturedProduct;