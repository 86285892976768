import { motion } from 'framer-motion';
import PageContent from '../components/Page/PageContent';
import HelmetContent from '../utils/HelmetContent';

const Page = (props) => {

  return (
    <motion.main
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className={`content`}
    >
      <HelmetContent
{...props}
        title={props.page.title}
        path={props.location.pathname}
      />
      <PageContent {...props} />
    </motion.main>
  )
}

export default Page;