import { Helmet } from 'react-helmet';

const HelmetContent = (props) => {

  const { image, path, title, description, options } = props;
  const { metaDescription } = options;

  return (
    <Helmet>
      {/* Primary Meta Tags */}
      <title>HÄN Archive</title>
      <meta
        name="title"
        content={`HÄN Archive${title ? ' | ' + title : ''}`}
      />
      <meta
        name="description"
        content={description ? description : metaDescription}
      />

      {/* Open Graph / Facebook */}
      <meta
        property="og:type"
        content="website"
      />
      <meta
        property="og:url"
        content={`https://han-archive.com${path ? path : "/"}`}
      />
      <meta
        property="og:title"
        content={`HÄN Archive${title ? ' | ' + title : ''}`}
      />
      <meta
        property="og:description"
        content={description ? description : metaDescription}
      />
      <meta
        property="og:image"
        content={image ? image : "https://han-archive.com/Screenshot.jpg"}
      />

      {/* Twitter */}
      <meta
        property="twitter:card"
        content="summary_large_image"
      />
      <meta
        property="twitter:url"
        content={`https://han-archive.com${path ? path : "/"}`}
      />
      <meta
        property="twitter:title"
        content={`HÄN Archive${title ? ' | ' + title : ''}`}
      />
      <meta
        property="twitter:description"
        content={description ? description : metaDescription}
      />
      <meta
        property="twitter:image"
        content={image ? image : "https://han-archive.com/Screenshot.jpg"}
      />
    </Helmet>
  )
};

export default HelmetContent;