import { useRef, useEffect } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import SearchResults from './SearchResults';

const Search = (props) => {

  const { windowHeight, windowWidth, articleCategories, setSearchHeight, location, searchTerm, setSearchTerm } = props;
  const element = useRef();

  const handleSearch = (value) => {
    props.navigate(`/search/?search=${value}`);
    setSearchTerm(value);
  }

  useEffect(() => {
    if (element.current && windowWidth && windowHeight) {
      setSearchHeight(element.current.offsetHeight);
    }
  }, [setSearchHeight, windowWidth, windowHeight]);

  return (
    <motion.div
      ref={element}
      initial={{ y: '-100%' }}
      animate={{ y: 0 }}
      exit={{ y: '-100%' }}
      transition={{
        style: 'linear',
        duration: 0.3,
        delay: 0.05
      }}
      style={{
        height: location.pathname === '/search' || location.pathname === '/search/' ? windowHeight + 'px' : null
      }}
      className={`search${location.pathname === '/search' || location.pathname === '/search/' ? ' search-page' : ''}`}
    >
      <form
        className="search__form"
        onSubmit={e => e.preventDefault()}
      >
        <input
          className="search__input"
          type="text"
          name="search"
          placeholder="Search&hellip;"
          value={searchTerm}
          onChange={(e) => {
            handleSearch(e.target.value);
          }}
        />
        <div className="search__categories">
          {
            articleCategories[0] &&
            articleCategories.map(
              (category, i) => (
                <label
                  key={i}
                  className="search__category"
                >
                  <span className="search__category__name">{category.name}</span>
                  <input
                    className="search__category__input"
                    type="radio"
                    name="category"
                    value={category.name}
                    checked={searchTerm === category.name}
                    onChange={(e) => {
                      if (e.target.checked === true) {
                        handleSearch(category.name);
                      } else {
                        handleSearch('');
                      }
                    }}
                  />
                </label>
              )
            )
          }
        </div>
      </form>
      <AnimatePresence>
        <SearchResults {...props} searchTerm={searchTerm} key={searchTerm} />
      </AnimatePresence>
    </motion.div>
  )
}

export default Search;