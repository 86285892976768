import { useState, useEffect } from 'react';

const Logo = (props) => {

  const [activeIndex, setActiveIndex] = useState(-1);

  useEffect(() => {
    let interval;
    let index = -1;

    const flashThrough = () => {
      if (index >= 4) {
        clearInterval(interval);
      } else {
        index++;
        setActiveIndex(index);
      }
    }

    interval = setInterval(flashThrough, 400);

    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <svg className="logo" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 784.02 192.83">
      <polygon className={`element${activeIndex >= 0 ? ' active' : ''}`} points="178.43 0 178.43 62.2 78.83 62.2 78.83 0 0 0 0 192.83 78.83 192.83 78.83 134.43 178.43 134.43 178.43 192.83 257.27 192.83 257.27 0 178.43 0" />
      <path className={`element${activeIndex >= 1 ? ' active' : ''}`} d="M391.97,56.97l13.63,62.1h-27.27l13.64-62.1ZM315.46,0l-52.88,192.83h97.92l5.31-21.97h52.3l5.31,21.97h97.92L468.47,0h-153.02Z" />
      <polygon className={`element${activeIndex >= 2 ? ' active' : ''}`} points="705.18 0 705.18 121.98 655.01 0 526.75 0 526.75 192.83 605.59 192.83 605.59 82.64 655.76 192.83 784.02 192.83 784.02 0 705.18 0" />
      <rect className={`element${activeIndex >= 3 ? ' active' : ''}`} x="264.09" y="0" width="35.39" height="33.39" />
      <rect className={`element${activeIndex >= 4 ? ' active' : ''}`} x="484.43" y="0" width="35.39" height="33.39" />
    </svg>
  )
}

export default Logo;