import { motion } from 'framer-motion';
import Product from '../components/Product';
import HelmetContent from '../utils/HelmetContent';

const Shop = (props) => {

  const { products } = props;

  return (
    <motion.main
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className="shop content"
    >
      <HelmetContent
{...props}
        title={`Shop`}
        path={props.location.pathname}
      />
      <div className="page__inner page__inner">
        <div className="shop__content">
          <div className="container-fluid">
            <ul className="products row">
              {
                products &&
                products[0] &&
                products.map(
                  (product, i) => (
                    <Product product={product} key={i} {...props} />
                  )
                )
              }
            </ul>
          </div>
        </div>
      </div>
    </motion.main>
  );
}

export default Shop;