import { useEffect } from 'react';

const Preview = (props) => {

  const { setPreviewItem } = props;

  useEffect(() => {
    const handleReceiveMessage = (event) => {
      const { data } = event;
      if (data) {
        const { collection, entry } = data;
        if (collection === 'pages') {
          setPreviewItem({ ...entry, collection: 'pages' });
        }
        if (collection === 'articles') {
          setPreviewItem({ ...entry, collection: 'articles' });
        }
        if (collection === 'editions') {
          setPreviewItem({ ...entry, collection: 'editions' });
        }
        if (collection === 'products') {
          setPreviewItem({ ...entry, collection: 'products' });
        }
      }
    }

    window.addEventListener('message', handleReceiveMessage, false);

    return () => {
      window.removeEventListener('message', handleReceiveMessage, false);
    }
  }, [setPreviewItem]);

  return null
}

export default Preview;