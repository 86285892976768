import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Edition from '../EditionThumbnail';

const PageContentRowEditions = (props) => {

  const { row, selected, editions } = props;
  const { value } = row;
  const [displayedEditions, setDisplayedEditions] = useState([]);

  useEffect(() => {
    const displayedEditionsList = [];
    if (selected === true) {
      if (value && value[0]) {
        for (let item of value) {
          for (let edition of editions) {
            if (edition._id === item._id) {
              displayedEditionsList.push(edition);
            }
          }
        }
      }
    } else {
      const number = parseFloat(value);
      if (number) {
        for (let i = 0; i < number; i++) {
          if (editions[i]) {
            displayedEditionsList.push(editions[i]);
          }
        }
      }
    }
    setDisplayedEditions(displayedEditionsList);
  }, [value, selected, editions]);

  return (
    <div className="editions-row__wrapper">
      <Link to="/editions">See all editions</Link>
      <ul className="row editions list--unstyled">
        {
          displayedEditions.map(
            (edition, index) => (
              <Edition {...props} edition={edition} key={index} />
            )
          )
        }
      </ul>
    </div>
  )
}

export default PageContentRowEditions;