import { Link } from 'react-router-dom';
import ReactMarkdown from 'react-markdown';
import LazyloadImage from './LazyloadImage';

const FeaturedArticleThumbnail = (props) => {

  const { article } = props;
  const { title, title_slug, subtitle, image, excerpt } = article;

  return (
    <div className={`featured`}>
      <div className="row">
        <div className="col-xxs-12 col-sm-6">
          <Link
            to={`/article/${title_slug}`}
            className="featured__link--image"
          >
            {
              image?.path && image.path !== '' &&
              <LazyloadImage image={image} />
            }
          </Link>
        </div>
        <div className="col-xxs-12 col-sm-6">
          <div className="article-thumbnail__inner">
            <h2 className="article-thumbnail__title">
              {title}
            </h2>
            <h3 className="article-thumbnail__subtitle">
              {subtitle}
            </h3>
          </div>
          {
            excerpt &&
            <div className="featured__intro">
              <ReactMarkdown>{excerpt}</ReactMarkdown>
            </div>
          }
          <Link
            to={`/article/${title_slug}`}
            className="featured__link"
          >Read More</Link>
        </div>
      </div>
    </div>
  )
}

export default FeaturedArticleThumbnail;