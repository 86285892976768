import LazyloadImage from '../LazyloadImage';

const PageContentRowDoubleImage = (props) => {

  const { row } = props;
  const { value } = row;

  const { image1, image2 } = value;

  return (
    <div className="row">
      <div className="col-xxs-12 col-sm-6 col--double-image">
        {
          image1 && image1.path &&
          <LazyloadImage image={image1} />
        }
      </div>
      <div className="col-xxs-12 col-sm-6 col--double-image">
        {
          image2 && image2.path &&
          <LazyloadImage image={image2} />
        }
      </div>
    </div>
  )
}

export default PageContentRowDoubleImage;