import { useState, useEffect, useCallback } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { getCookieConsentValue } from 'react-cookie-consent';
import Content from './components/Content';
import { fetchCockpitData } from './utils/cockpit';

const App = (props) => {

  const [windowDimensions, setWindowDimensions] = useState({
    windowHeight: window.innerHeight,
    windowWidth: window.innerWidth
  });
  
  const [cookiesAreAccepted, setCookiesAreAccepted] = useState(getCookieConsentValue() ? true : false);
  const [searchIsActive, setSearchIsActive] = useState(false);
  const [searchHeight, setSearchHeight] = useState(0);
  const [scrollAmount, setScrollAmount] = useState(0);
  const [menuIsActive, setMenuIsActive] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();
  const [articles, setArticles] = useState([]);
  const [articleCategories, setArticleCategories] = useState([]);
  const [products, setProducts] = useState([]);
  const [editions, setEditions] = useState([]);
  const [pages, setPages] = useState([]);
  const [homepage, setHomepage] = useState({});
  const [options, setOptions] = useState({});
  const [submit, setSubmit] = useState({});
  const [shop, setShop] = useState({});
  const [previewItem, setPreviewItem] = useState({});
  const [searchTerm, setSearchTerm] = useState(location.search.indexOf('?search=') === 0 ? location.search.replace('?search=', '').replace(/%20/g, ' ') : '');
  const [isInitialised, setInitialised] = useState(false);

  const { isFullSite } = options;

  useEffect(() => {
    let timeout = setTimeout(() => {
      if (isFullSite === true || previewItem._id) {
        setInitialised(true);
      } else {
        setInitialised(false);
      }
    }, 2400);
    
    if (previewItem._id) {
      setInitialised(true);
    }

    return () => {
      clearTimeout(timeout);
    };
  }, [isFullSite, previewItem._id]);

  const fetchContent = useCallback(() => {
    fetchCockpitData('options', setOptions, false);
    if (isFullSite === true || previewItem._id) {
      fetchCockpitData('articles', setArticles, true, null, { published: true });
      fetchCockpitData('articleCategories', setArticleCategories, true);
      fetchCockpitData('products', setProducts, true, null, { published: true });
      fetchCockpitData('editions', setEditions, true);
      fetchCockpitData('pages', setPages, true);
      fetchCockpitData('homepage', setHomepage, false);
      fetchCockpitData('submit', setSubmit, false);
      fetchCockpitData('shop', setShop, false);
    }
  }, [isFullSite, previewItem]);

  useEffect(() => fetchContent(), [fetchContent]);

  useEffect(() => {
    const handleWindowResize = () => {
      setWindowDimensions({
        windowHeight: window.innerHeight,
        windowWidth: window.innerWidth
      });
    }
    handleWindowResize();
    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    }
  }, []);

  useEffect(() => {
    if (location.pathname !== '/search') {
      setSearchIsActive(false);
    }
    setMenuIsActive(false);
  }, [ location.pathname ]);

  return (
    <Content
      {...props}
      navigate={navigate}
      location={location}
      windowWidth={windowDimensions.windowWidth}
      windowHeight={windowDimensions.windowHeight}
      cookiesAreAccepted={cookiesAreAccepted}
      setCookiesAreAccepted={setCookiesAreAccepted}
      articles={articles}
      setArticles={setArticles}
      articleCategories={articleCategories}
      products={products}
      editions={editions}
      pages={pages}
      searchIsActive={searchIsActive}
      setSearchIsActive={setSearchIsActive}
      searchTerm={searchTerm}
      setSearchTerm={setSearchTerm}
      homepage={homepage}
      options={options}
      previewItem={previewItem}
      setPreviewItem={setPreviewItem}
      scrollAmount={scrollAmount}
      setScrollAmount={setScrollAmount}
      searchHeight={searchHeight}
      setSearchHeight={setSearchHeight}
      menuIsActive={menuIsActive}
      setMenuIsActive={setMenuIsActive}
      isInitialised={isInitialised}
      setInitialised={setInitialised}
      fetchContent={fetchContent}
      submit={submit}
      shop={shop}
    />
  );
}

export default App;
