import { Link } from 'react-router-dom';
import parse from 'html-react-parser';
import EditionViewer from './EditionViewer';


const EditionThumbnail = (props) => {

  const { edition, shopLink } = props;
  const { imageFront } = edition;

  return (
    <li className="edition col-xxs-12">
      <div className="edition__inner row">
        <div className="col-xxs-12 col-xs-11 offset-xs-1 col-sm-8 offset-sm-2 col-md-5 offset-md-1 order-md-0 order-xxs-0 order-sm-1">
          {
            imageFront && imageFront.path && imageFront.path !== '' &&
            <EditionViewer {...props} />
          }
        </div>
        <div className="col-xxs-12 col-xs-11 offset-xs-1 col-sm-8 offset-sm-2 col-md-5 offset-md-0 order-md-2 edition__info order-xxs-2">
          <div className="row">
            <div className="col-md-6">
              <h3 className="edition__title">{edition.title}</h3>
            </div>
          </div>
          {
            edition.excerpt &&
            <div className="edition__excerpt">
              {parse(edition.excerpt)}
            </div>
          }
          {
            <Link
              className="edition__link"
              to={shopLink === true ? '/shop' : `/editions/${edition.title_slug}`}
            >
              {shopLink === true ? 'Buy' : 'Find out more'}
            </Link>
          }
        </div>
      </div>
    </li>
  );
}

export default EditionThumbnail;