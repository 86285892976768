import { motion } from 'framer-motion';
import MenuItem from './MenuItem';

const MobileMenu = (props) => {

  const { options, location } = props;
  const { menu } = options;

  return (
    <motion.nav
      initial={{ x: '100%' }}
      animate={{ x: 0 }}
      exit={{ x: '-100%' }}
      transition={{ style: 'ease' }}
      className="mobile-menu"
    >
      <div className="mobile-menu__inner">
        {
          menu &&
          menu[0] &&
          menu.map(
            (item, index) => (
              <MenuItem
                {...props}
                key={index}
                item={item}
                pathname={location.pathname}
                className="mobile-menu__item"
              />
            )
          )
        }
      </div>
    </motion.nav>
  )
};

export default MobileMenu;