import { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import SearchResult from './SearchResult';

const SearchResults = (props) => {

  const { searchTerm, articles, products, editions } = props;
  const [searchResults, setSearchResults] = useState([]);

  useEffect(() => {
    const searchResultsArray = [];
    if (searchTerm && searchTerm !== '') {
      for (let article of articles) {
        let articleIsInArray = false;
        if (article.title.toLowerCase().indexOf(searchTerm.toLowerCase()) > -1) {
          articleIsInArray = true;
        }
        if (article.tags) {
          for (let tag of article.tags) {
            if (tag.display) {
              if (tag.display.toLowerCase().indexOf(searchTerm.toLowerCase()) > -1) {
                articleIsInArray = true;
              }
            }
          }
        }
        if (articleIsInArray === true) {
          searchResultsArray.push({
            ...article,
            collection: 'articles'
          });
        }
      }
      for (let product of products) {
        let productIsInArray = false;
        if (product.title.toLowerCase().indexOf(searchTerm.toLowerCase()) > -1) {
          productIsInArray = true;
        }
        if (productIsInArray === true) {
          searchResultsArray.push({
            ...product,
            collection: 'products'
          });
        }
      }
      for (let edition of editions) {
        let editionIsInArray = false;
        if (edition.title.toLowerCase().indexOf(searchTerm.toLowerCase()) > -1) {
          editionIsInArray = true;
        }
        if (editionIsInArray === true) {
          searchResultsArray.push({
            ...edition,
            collection: 'editions'
          });
        }
      }
    }

    setSearchResults(searchResultsArray);
  }, [searchTerm, articles, products, editions]);

  return (
    <motion.ul
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className="search__results row"
    >
      {
        searchResults[0] &&
        searchResults.map((searchResult, i) => (
          <SearchResult key={i} searchResult={searchResult} />
        ))
      }
    </motion.ul>
  )

}

export default SearchResults;