import { useState, useEffect } from 'react';
import Edition from '../EditionThumbnail';

const PageContentRowFeaturedEdition = (props) => {

  const { row, editions } = props;
  const { value } = row;
  const [edition, setEdition] = useState({});

  useEffect(() => {
    if (value && value._id) {
      for (let editionObject of editions) {
        if (editionObject._id === value._id) {
          setEdition(editionObject);
        }
      }
    }
  }, [value, editions]);

  if (edition._id) {
    return (
      <ul className="row editions list--unstyled">
        <Edition edition={edition} {...props} />
      </ul>
    );
  } else {
    return null;
  }
}

export default PageContentRowFeaturedEdition;