import LazyloadImage from '../LazyloadImage';
import parse from 'html-react-parser';

const PageContentRowImageAndText = (props) => {

  const { row } = props;
  const { value } = row;

  const { image, text } = value;

  return (
    <div className="row">
      <div className="col-xxs-12 col-sm-6">
        {
          image && image.path &&
          <LazyloadImage image={image} />
        }
      </div>
      <div className="col-xxs-12 col-sm-6">
        {
          text && text !== '' &&
          <div className="text__wrapper text__wrapper--not-full">
            {text ? parse(text) : ''}
          </div>
        }
      </div>
    </div>
  )
}

export default PageContentRowImageAndText;