import PageContentRowLargeText from './PageContentRowLargeText';
import PageContentRowText from './PageContentRowText';
import PageContentRowLargeImage from './PageContentRowLargeImage';
import PageContentRowVideo from './PageContentRowVideo';
import PageContentRowImageAndText from './PageContentRowImageAndText';
import PageContentRowDoubleImage from './PageContentRowDoubleImage';
import PageContentRowFeaturedProduct from './PageContentRowFeaturedProduct';
import PageContentRowFeaturedArticle from './PageContentRowFeaturedArticle';
import PageContentRowFeaturedEdition from './PageContentRowFeaturedEdition';
import PageContentRowProducts from './PageContentRowProducts';
import PageContentRowArticles from './PageContentRowArticles';
import PageContentRowEditions from './PageContentRowEditions';

const PageContentRow = (props) => {

  const { row } = props;
  const { field } = row;
  const { name } = field;

  switch (name) {
    case 'largeText':
      return <PageContentRowLargeText {...props} />
    case 'text':
      return <PageContentRowText {...props} />
    case 'largeImage':
      return <PageContentRowLargeImage {...props} />
    case 'video':
      return <PageContentRowVideo {...props} />
    case 'videoUrl':
      return <PageContentRowVideo {...props} />
    case 'imageAndText':
      return <PageContentRowImageAndText {...props} />
    case 'doubleImage':
      return <PageContentRowDoubleImage {...props} />
    case 'featuredProduct':
      return <PageContentRowFeaturedProduct {...props} />
    case 'featuredArticle':
      return <PageContentRowFeaturedArticle {...props} />
    case 'featuredEdition':
      return <PageContentRowFeaturedEdition {...props} editionLink={true} />
    case 'featuredEditionShopLink':
      return <PageContentRowFeaturedEdition {...props} shopLink={true} />
    case 'selectedProducts':
      return <PageContentRowProducts {...props} selected={true} />
    case 'selectedArticles':
      return <PageContentRowArticles {...props} selected={true} />
    case 'selectedEditions':
      return <PageContentRowEditions {...props} selected={true} editionsLink={true} />
    case 'latestProducts':
      return <PageContentRowProducts {...props} />
    case 'latestArticles':
      return <PageContentRowArticles {...props} />
    case 'latestEditions':
      return <PageContentRowEditions {...props} />
    default:
      return null;
  }
}

export default PageContentRow;