import { useState, useEffect } from 'react';
import Product from '../Product';

const PageContentRowProducts = (props) => {

  const { row, selected, products } = props;
  const { value } = row;
  const [displayedProducts, setDisplayedProducts] = useState([]);

  useEffect(() => {
    const displayedProductsList = [];
    if (selected === true) {
      if (value && value[0]) {
        for (let item of value) {
          for (let product of products) {
            if (product._id === item._id) {
              displayedProductsList.push(product);
            }
          }
        }
      }
    } else {
      const number = parseFloat(value);
      if (number) {
        for (let i = 0; i < number; i++) {
          if (products[i]) {
            displayedProductsList.push(products[i]);
          }
        }
      }
    }
    setDisplayedProducts(displayedProductsList);
  }, [value, selected, products]);

  return (
    <ul className="row products list--unstyled">
      {
        displayedProducts.map(
          (product, index) => (
            <Product {...props} product={product} key={index} />
          )
        )
      }
    </ul>
  )
}

export default PageContentRowProducts;