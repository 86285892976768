const ArchiveSearch = (props) => {

  const { articleCategories, archiveSearchTerm, setArchiveSearchTerm, windowWidth } = props;

  const handleSearch = (value) => {
    setArchiveSearchTerm(value);
  }

  return (
    <div
      className={`archive__search`}
    >
      <form
        className="search__form"
        onSubmit={e => e.preventDefault()}
      >
        <input
          className="search__input"
          type="text"
          name="search"
          placeholder={windowWidth >= 768 ? `Search articles…` : 'Search…'}
          value={archiveSearchTerm}
          onChange={(e) => {
            handleSearch(e.target.value);
          }}
        />
        <div className="search__categories">
          {
            articleCategories[0] &&
            articleCategories.map(
              (category, i) => (
                <label
                  key={i}
                  className="search__category"
                >
                  <span className="search__category__name">{category.name}</span>
                  <input
                    className="search__category__input"
                    type="radio"
                    name="category"
                    value={category.name}
                    checked={archiveSearchTerm === category.name}
                    onChange={(e) => {
                      if (e.target.checked === true) {
                        handleSearch(category.name);
                      } else {
                        handleSearch('');
                      }
                    }}
                  />
                </label>
              )
            )
          }
        </div>
      </form>
    </div>
  )
}

export default ArchiveSearch;