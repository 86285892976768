import { useRef, useState, useEffect, Suspense } from 'react';
import * as THREE from 'three';
import { Canvas, useFrame } from '@react-three/fiber';
import { getImage } from '../utils/cockpit';

const lerp = (start, end, amount) => {
  return (1 - amount) * start + amount * end;
}

const EditionRotation = (props) => {

  const { hasInteracted, targetRotation, mesh } = props;

  useFrame(({ mouse }) => {
    if (hasInteracted === false) {
      targetRotation.current.x = 0;
      if (mesh.current) {
        mesh.current.rotation.y += 0.012;
      }
    } else {
      targetRotation.current.y = mouse.x * Math.PI;
      targetRotation.current.x = -mouse.y * Math.PI;
      if (mesh.current) {
        mesh.current.rotation.y = lerp(mesh.current.rotation.y % (Math.PI * 2), targetRotation.current.y, 0.03);
      }
    }
    if (mesh.current) {
      mesh.current.rotation.x = lerp(mesh.current.rotation.x % (Math.PI * 2), targetRotation.current.x, 0.03);
    }
  });

  return null;
}

const EditionViewer = (props) => {

  const { edition } = props;
  const { imageFront, imageBack, imageSpine } = edition;
  const [height, setHeight] = useState(1 / 0.5625);
  const [depth, setDepth] = useState(0.03);
  const [dimensions, setDimensions] = useState([1, height, depth]);
  const [front, setFront] = useState('');
  const [back, setBack] = useState('');
  const [spine, setSpine] = useState('');
  const [envMap, setEnvMap] = useState({});
  const [hasInteracted, setHasInteracted] = useState(false);
  const interactionTimeout = useRef({});
  const mesh = useRef();
  const targetRotation = useRef({ x: 0, y: 0 });

  useEffect(() => {
    const canvas = document.createElement('canvas');
    canvas.width = 160;
    canvas.height = 160;
    const ctx = canvas.getContext('2d');
    const grd = ctx.createRadialGradient(128, 128, 5, 128, 128, 160);
    grd.addColorStop(0.6, "white");
    grd.addColorStop(0.8, "black");
    ctx.fillStyle = grd;
    ctx.fillRect(0, 0, canvas.width, canvas.height);
    ctx.beginPath();
    ctx.arc(128, 128, 16, 0, 2 * Math.PI);
    ctx.fillStyle = 'black';
    ctx.fill();
    ctx.closePath();
    ctx.fillRect(128, 128, 90, 0, 2 * Math.PI);
    canvas.setAttribute('style', 'position:absolute; z-index: 999999; top: 0; left: 0;');
    const envMapSrc = canvas.toDataURL();
    new THREE.TextureLoader().load(envMapSrc, (envMapTexture) => {
      envMapTexture.mapping = THREE.EquirectangularReflectionMapping;
      setEnvMap(envMapTexture);
    });
  }, []);

  useEffect(() => {
    setDimensions([1, height, depth]);
  }, [height, depth]);

  useEffect(() => {
    let isMounted = true;
    const img = document.createElement('img');
    const onImageLoad = () => {
      if (isMounted === true) {
        setHeight(img.naturalHeight / img.naturalWidth);
      }
    }

    if (front !== '') {
      img.addEventListener('load', onImageLoad);
      img.src = front;
    }

    return () => {
      isMounted = false;
    }
  }, [front]);

  useEffect(() => {
    let isMounted = true;
    const img = document.createElement('img');
    const onImageLoad = () => {
      if (isMounted === true) {
        setDepth(img.naturalWidth / img.naturalHeight);
      }
    }

    if (spine !== '') {
      img.addEventListener('load', onImageLoad);
      img.src = spine;
    }

    return () => {
      isMounted = false;
    }
  }, [spine, height]);

  useEffect(() => {
    return () => {
      clearTimeout(interactionTimeout.current);
    }
  }, []);

  useEffect(() => {
    if (imageFront && imageFront.path && imageFront.path !== '') {
      getImage(imageFront.path, 1200, 1200, 30, (src) => {setFront(src)});
    };
    if (imageBack && imageBack.path && imageBack.path !== '') {
      getImage(imageBack.path, 1200, 1200, 30, setBack);
    };
    if (imageSpine && imageSpine.path && imageSpine.path !== '') {
      getImage(imageSpine.path, 60, 60, 30, setSpine);
    };

  }, [imageFront, imageBack, imageSpine]);

  if ((front !== '' || !imageFront || imageFront === '') &&
  (back !== '' || !imageBack || imageBack === '') &&
  (spine !== '' || !imageSpine || imageSpine === '')) {
    return (
      <div className="edition__canvas__wrapper">
        <div className="edition__canvas__inner">
          <Canvas
            onPointerMove={() => {
              clearTimeout(interactionTimeout.current);
              setHasInteracted(true);
              interactionTimeout.current = setTimeout(() => {
                setHasInteracted(false);
              }, 2000);
            }}
          >
            <EditionRotation
              {...props}
              mesh={mesh}
              targetRotation={targetRotation}
              hasInteracted={hasInteracted}
            />
            <ambientLight intensity="0.4" />
            <pointLight position={[2, 6, 0]} />
            <Suspense fallback={null}>
              <mesh scale={[3.5, 3.5, 3.5]} ref={mesh}>
                <boxGeometry args={dimensions} />
                {/* pages edge */}
                <meshPhysicalMaterial
                  reflectivity={0.5}
                  metalness={0.4}
                  roughness={0.31}
                  attach={`material-0`}
                  key={'1'}
                  color={0xffffff}
                  envMap={envMap.uuid ? envMap : null}
                />
                {/* spine */}
                <meshPhysicalMaterial
                  reflectivity={0.99}
                  metalness={1}
                  roughness={0.01}
                  needsUpdate={true}
                  attach={`material-1`}
                  key={'2'}
                  color={0xffffff}
                  envMap={envMap.uuid ? envMap : null}
                  map={spine !== '' ? new THREE.TextureLoader().load(spine) : null}
                />
                {/* top */}
                <meshPhysicalMaterial
                  reflectivity={0.5}
                  metalness={0.4}
                  roughness={0.31}
                  needsUpdate={true}
                  attach={`material-2`}
                  key={'3'}
                  color={0xffffff}
                  envMap={envMap.uuid ? envMap : null}
                />
                {/* bottom */}
                <meshPhysicalMaterial
                  reflectivity={0.5}
                  metalness={0.4}
                  roughness={0.31}
                  needsUpdate={true}
                  attach={`material-3`}
                  key={'4'}
                  color={0xffffff}
                  envMap={envMap.uuid ? envMap : null}
                />
                {/* front */}
                <meshPhysicalMaterial
                  reflectivity={0.99}
                  metalness={1}
                  roughness={0.01}
                  needsUpdate={true}
                  attach={`material-4`}
                  map={front !== '' ? new THREE.TextureLoader().load(front) : null}
                  key={'5'}
                  color={0xffffff}
                  envMap={envMap.uuid ? envMap : null}
                />
                {/* back */}
                <meshPhysicalMaterial
                  reflectivity={0.99}
                  metalness={1}
                  roughness={0.01}
                  needsUpdate={true}
                  attach={`material-5`}
                  key={'6'}
                  color={0xffffff}
                  envMap={envMap.uuid ? envMap : null}
                  map={back !== '' ? new THREE.TextureLoader().load(back) : null}
                />
              </mesh>
            </Suspense>
          </Canvas>
        </div>
      </div>
    );
  } else {
    return null;
  }
};

export default EditionViewer;