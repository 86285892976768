import { useState, useRef, useEffect, useCallback } from 'react';
import ReactPlayer from 'react-player';
import { cockpitUrl } from '../utils/cockpit';

const VideoPlayer = (props) => {

  const { url, isPlaying, setIsPlaying, setIsActuallyPlaying, autoHeight, muted, setMuted,  } = props;
  const [ aspectRatio, setAspectRatio ] = useState(0.5625);

  const player = useRef();
  const container = useRef();
  const videoPlayer = useRef();

  const isMutedRef = useRef(muted);

  const handlePlay = useCallback((e) => {
    setIsPlaying(true);
  }, [ setIsPlaying, ]);

  useEffect(() => {
    handlePlay();
    setIsPlaying(true);

    const handleToggleMute = (e) => {
      isMutedRef.current = !isMutedRef.current;
      setMuted(isMutedRef.current);
    }

    window.addEventListener('click', handlePlay);
    window.addEventListener('click', handleToggleMute);

    return () => {
      window.removeEventListener('click', handlePlay);
      window.removeEventListener('click', handleToggleMute);
    }
  }, [ handlePlay, setIsPlaying, setMuted, ]);

  if (url) {
    return (
      <div
        ref={ container }
        className="video-player__container"
      >
        <div
          className={`video-player${url.indexOf('storage') === 0 || url.indexOf('/storage') === 0 ? ' video-player--video-element' : ''}${autoHeight === true ? ' height--auto' : ''}`}
          ref={ videoPlayer }
          style={{
            height: autoHeight === true || !container.current ?
              'auto' :
                container.current.offsetHeight / container.current.offsetWidth > aspectRatio ? '100%' : container.current.offsetWidth / aspectRatio + 'px',
            width: autoHeight === true || !container.current ? '100%' :
              container.current.offsetHeight / container.current.offsetWidth > aspectRatio ? container.current.offsetHeight / aspectRatio + 'px' : '100%',
            transform: 'translate(-50%, -50%)',
            left: '50%',
            top: '50%'
          }}
        >
          <div className="video__wrapper">
            <ReactPlayer
              ref={player}
              url={url.indexOf('storage') === 0 ? `${cockpitUrl}/${url}` : url.indexOf('/storage') === 0 ? `${cockpitUrl}${url}` : url}
              className="video-player__player"
              playsInline={ true }
              playing={ isPlaying ? isPlaying : true }
              muted={ muted }
              height={'100%'}
              width={'100%'}
              loop={ true }
              allow="autoplay"

              volume={ 1 }

              onReady={ () => {
                setIsPlaying && setIsPlaying(true);
              } }
              onError={ (e) => {
                setIsPlaying && setIsPlaying(false);
                setIsActuallyPlaying && setIsActuallyPlaying(false);
              } }

              onPlay={ () => {
                setIsActuallyPlaying && setIsActuallyPlaying(true);
              } }
              onPause={() => setIsActuallyPlaying && setIsActuallyPlaying(false)}
              onCanPlay={(e) => {
                if (url.indexOf('storage') === 0 || url.indexOf('/storage') === 0) {
                  if (player.current.getInternalPlayer() && player.current.getInternalPlayer().videoWidth) {
                    setAspectRatio(player.current.getInternalPlayer().videoWidth / player.current.getInternalPlayer().videoHeight);
                  }
                }
              }}
            />
          </div>
        </div>
        <button
          className='mute-button'
          onClick={ () => {
            setMuted(!muted);
          } }
        >
          <span className='mute-button__text'>
            { muted ? 'Unmute' : 'Mute' }
          </span>
        </button>
      </div>
    );
  } else {
    return null;
  }
}

export default VideoPlayer;