import { useState, useEffect } from 'react';
import FeaturedArticleThumbnail from '../FeaturedArticleThumbnail';

const PageContentRowFeaturedArticle = (props) => {

  const { row, articles } = props;
  const { value } = row;
  const [article, setArticle] = useState({});

  useEffect(() => {
    if (value && value._id) {
      for (let articleObject of articles) {
        if (articleObject._id === value._id) {
          setArticle(articleObject);
        }
      }
    }
  }, [value, articles]);

  if (article._id) {
    return (
      <div className="row">
        <FeaturedArticleThumbnail article={article} {...props} />
      </div>
    );
  } else {
    return null;
  }
}

export default PageContentRowFeaturedArticle;