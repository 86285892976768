import { useRef, useState, useEffect } from 'react';

const LargeTextLine = (props) => {

  const { lineIndex, text, windowWidth, windowHeight, scrollAmount } = props;
  const unscrolledPosition = useRef(0);
  const line = useRef();
  const lineInner = useRef();

  useEffect(() => {
    lineInner.current.style.transition = null;
    const lineInnerWidth = lineInner.current.offsetWidth;
    const lineWidth = windowWidth - 40;
    const offsetLeft = Math.floor(Math.random() * (lineWidth - lineInnerWidth));
    unscrolledPosition.current = offsetLeft;
    lineInner.current.style.transform = `translateX(${offsetLeft}px)`;
    lineInner.current.style.transition = 'transform 0.9s ease';
  }, [windowWidth]);

  useEffect(() => {
    let timeout;
    const offsetTop = line.current.offsetTop;
    const unscrolled = unscrolledPosition.current;
    if (scrollAmount > 1) {
      if (scrollAmount >= offsetTop - windowHeight * 0.5) {
        const offsetLeft = 0;
        // const offsetLeft = Math.min(Math.max(0, unscrolled - (scrollAmount - offsetTop) - windowHeight * 0.5), unscrolled);
        lineInner.current.style.transform = `translateX(${offsetLeft}px)`;
      } else {
        lineInner.current.style.transform = `translateX(${unscrolled}px)`;
      }
    } else {
      lineInner.current.style.transform = `translateX(${unscrolled}px)`;
    }

    return () => {
      clearTimeout(timeout);
    }
  }, [scrollAmount, windowHeight]);

  return (
    <span
      ref={line}
      className="large-text__line"
      key={lineIndex}
    >
      <span ref={lineInner} className="large-text__line__inner">
        {text}
      </span>
    </span>
  )
}

const PageContentRowLargeText = (props) => {

  const { row, windowWidth } = props;
  const { value } = row;
  const [textLines, setTextLines] = useState([]);
  const rowElement = useRef();
  const element = useRef();

  useEffect(() => {
    const allCharactersArray = value.split('');
    const spanOffsets = [];
    const largeTextTemporaryElement = document.createElement('h2');
    largeTextTemporaryElement.className = 'large-text large-text--temporary';
    rowElement.current.appendChild(largeTextTemporaryElement);
    
    for (let i = 0; i < allCharactersArray.length; i++) {
      const newSpan = document.createElement('span');
      newSpan.className = 'large-text__character';
      newSpan.textContent = allCharactersArray[i];
      largeTextTemporaryElement.appendChild(newSpan);
    }

    for (let i = 0; i < largeTextTemporaryElement.children.length; i++) {
      const newSpan = largeTextTemporaryElement.children[i];
      const offsetTop = newSpan.offsetTop;

      let offsetTopExists = false;

      if (spanOffsets.length > 0) {
        for (let j = 0; j < spanOffsets.length; j++) {
          if (offsetTop === spanOffsets[j].offsetTop) {
            offsetTopExists = true;
            spanOffsets[j].characters += allCharactersArray[i];
          }
        }
      }

      if (offsetTopExists === false) {
        spanOffsets.push({
          offsetTop: offsetTop,
          characters: allCharactersArray[i]
        });
      }
    }

    if (spanOffsets.length > 0) {
      const textLinesArray = [];
      for (let i = 0; i < spanOffsets.length; i++) {
        textLinesArray.push(spanOffsets[i].characters);
      }
      setTextLines(textLinesArray);
    } else {
      setTextLines([]);
    }

    rowElement.current.removeChild(largeTextTemporaryElement);

  }, [value, windowWidth]);

  return (
    <div className="row" ref={rowElement}>
      {
        value && value !== '' &&
        <h2 className="large-text" ref={element}>
          {
            textLines[0] &&
            textLines.map((text, lineIndex) => (
              <LargeTextLine {...props} text={text} lineIndex={lineIndex} key={lineIndex}/>
            ))
          }
        </h2>
      }
    </div>
  )
}

export default PageContentRowLargeText;