import VideoPlayer from '../VideoPlayer';

const PageContentRowVideo = (props) => {

  const { row } = props;
  const { value } = row;

  if (value && value !== '') {
    return (
      <div className="row">
        <div className="col-xxs-12">
          <VideoPlayer {...props} url={value} />
        </div>
      </div>
    );
  } else {
    return null;
  }
}

export default PageContentRowVideo;