import { useState, useEffect } from 'react';
import parse from 'html-react-parser';
import ReactMarkdown from 'react-markdown';
import LazyloadImage from '../LazyloadImage';
import VideoPlayer from '../VideoPlayer';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const ItemSlide = (props) => {

  const {item, index} = props;
  const { path } = item;
  const [type, setType] = useState('none');

  useEffect(() => {
    if (path) {
      const pathToLower = path.toLowerCase();
      const length = pathToLower.length;

      if (pathToLower.indexOf('.jpg') === length - 4) {
        setType('image');
      }
      if (pathToLower.indexOf('.jpeg') === length - 5) {
        setType('image');
      }
      if (pathToLower.indexOf('.png') === length - 4) {
        setType('image');
      }
      if (pathToLower.indexOf('.gif') === length - 4) {
        setType('image');
      }
      if (pathToLower.indexOf('.webp') === length - 5) {
        setType('image');
      }
      if (pathToLower.indexOf('.mp4') === length - 4) {
        setType('video');
      }
      if (pathToLower.indexOf('.ogg') === length - 4) {
        setType('video');
      }
    }
  }, [path]);

  if (type !== 'none') {
    return (
      <div index={index} className="article--slideshow__slide">
        {
          type === 'image' &&
          <LazyloadImage image={item} />
        }
        {
          type === 'video' &&
          <VideoPlayer url={path} />
        }
      </div>
    )
  } else {
    return null;
  }
}

const ArticleLayoutMediaGallerySlideshow = (props) => {

  const { article } = props;
  const { title, subtitle, excerpt, gallery, content } = article;

  const settings = {
          dots: true,
          infinite: true,
          speed: 500,
          slidesToShow: 1,
          slidesToScroll: 1,
          variableWidth: true
        }

  return (
    <div className="wrapper">
      <Slider {...settings} >
        {
          gallery &&
          gallery[0] &&
          gallery.map(
            (item, i) => (
              <ItemSlide key={i} index={i} item={item} />
            )
          )
        }
      </Slider>
      <div className="container-fluid">
        <div className="row">
          <div className="col-xxs-12">
          <div className="article__title__wrapper">
            <h2 className="article-thumbnail__title">{title}</h2>
            <h3 className="article-thumbnail__subtitle">{subtitle}</h3>
          </div>
          {
            excerpt &&
            <div className="featured__intro">
              <ReactMarkdown>{excerpt}</ReactMarkdown>
            </div>
            }
            {
              content && content !== '' &&
              <div className="text__wrapper article__text__wrapper">
                {parse(content)}
              </div>
            }
          </div>
        </div>
      </div>
    </div>
  )
}

export default ArticleLayoutMediaGallerySlideshow;