import LazyloadImage from '../LazyloadImage';

const PageContentRowLargeImage = (props) => {

  const { row } = props;
  const { value } = row;

  if (value && value.path) {
    return (
      <div className="row">
        <div className="col-xxs-12">
          <div className="image__wrapper image__wrapper--full-width">
            <LazyloadImage image={value} />
          </div>
        </div>
      </div>
    );
  } else {
    return null;
  }
}

export default PageContentRowLargeImage;