import {Editor} from '@tinymce/tinymce-react';

const SubmissionTextEditor = (props) => {

  const { textValue, setTextValue } = props;
  
  return (
    <div className="col-xxs-12 col-md-6">
      <div
        className="submit__form__item"
        style={{ padding: 0 }}
      >
        <Editor
          apiKey={'rqfooednb6xjvb5ce8oaem5qxye2buoah1absbwb77ogb3ai'}
          value={textValue}
          onEditorChange={(e) => setTextValue(e)}
        />
      </div>
    </div>
  );
};

export default SubmissionTextEditor;