import { motion } from 'framer-motion';
import ArticleLayoutArticle from '../components/Article/ArticleLayoutArticle';
import ArticleLayoutAudio from '../components/Article/ArticleLayoutAudio';
import ArticleLayoutMediaGallerySlideshow from '../components/Article/ArticleLayoutMediaGallerySlideshow';
import ArticleLayoutVideo from '../components/Article/ArticleLayoutVideo';
import ArticleLayoutContribution from '../components/Article/ArticleLayoutContribution';
import HelmetContent from '../utils/HelmetContent';

const Article = (props) => {

  const { article } = props;
  const { layout, author, authorLink } = article;

  return (
    <motion.main
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className={`content`}
    >
      <HelmetContent
{...props}
        title={article.title}
        image={article.image ? article.image.path : null}
        description={article.excerpt}
        path={props.location.pathname}
      />
      {
        layout === 'article' &&
        <ArticleLayoutArticle {...props} />
      }
      {
        layout === 'slideshow' &&
        <ArticleLayoutMediaGallerySlideshow {...props} />
      }
      {
        layout === 'video' &&
        <ArticleLayoutVideo {...props} />
      }
      {
        layout === 'audio' &&
        <ArticleLayoutAudio {...props} />
      }
      {
        layout === 'contribution' &&
        <ArticleLayoutContribution {...props} />
      }
      <div className="container-fluid">
        <div className="row">
          <div className="col-xxs-12 col-sm-6">
            {
              author && author !== '' &&
              <p className="article__author">
                {
                  authorLink && authorLink !== '' ?
                  <a
                    href={authorLink}
                    rel="noreferrer"
                    target="_blank"
                      >{author}</a>
                      :
                      author
                }
                </p>
            }
          </div>
        </div>
      </div>
    </motion.main>
  )
}

export default Article;