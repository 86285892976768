import { useState } from 'react';
import { motion } from 'framer-motion';
import parse from 'html-react-parser';
import SubmissionTypeSelect from '../components/SubmissionTypeSelect.js';
import SubmissionImageUploader from '../components/SubmissionImageUploader.js';
import { useEffect } from 'react';
import { uploadAsset, saveCollectionEntry } from '../utils/cockpit';
import SubmissionTextEditor from '../components/SubmissionTextEditor.js';
import HelmetContent from '../utils/HelmetContent.js';

const Submit = (props) => {

  const { submit } = props;

  const [uploadSuccess, setUploadSuccess] = useState(false);
  const [uploadIsProcessing, setUploadIsProcessing] = useState(false);
  const [submissionType, setSubmissionType] = useState('none');
  const [submissionTypes] = useState([
    'none',
    'text',
    'image',
    'video',
    'audio'
  ]);
  const [uploadFailed, setUploadFailed] = useState(false);
  const [titleValue, setTitleValue] = useState('');
  const [nameValue, setNameValue] = useState('');
  const [linkValue, setLinkValue] = useState('');
  const [imageValue, setImageValue] = useState({});
  const [textValue, setTextValue] = useState('');
  const [captionValue, setCaptionValue] = useState('');
  const [videoUrlValue, setVideoUrlValue] = useState('');
  const [audioUrlValue, setAudioUrlValue] = useState('');

  useEffect(() => {
    if (uploadSuccess === true) {
      setUploadFailed(false);
      setUploadIsProcessing(false);
      setTitleValue('');
      setNameValue('');
      setLinkValue('');
      setSubmissionType('none');
      setTextValue('');
      setImageValue('');
      setVideoUrlValue('');
      setAudioUrlValue('');
    }
  }, [uploadSuccess]);

  const createPost = (image) => {
    setUploadIsProcessing(true);
    const data = {
      title: titleValue,
      title_slug: encodeURI(titleValue),
      image: image ? image : null,
      content: submissionType === 'text' ? textValue : null,
      layout: submissionType === 'video' ? 'video' : 'contribution',
      videoEmbed: submissionType === 'video' ? videoUrlValue : null,
      audioEmbed: submissionType === 'audio' ? audioUrlValue : null,
      author: nameValue,
      authorLink: linkValue,
      caption: captionValue ? captionValue : null
    }

    saveCollectionEntry(
      'articles', 
      data, 
      () => {
        setUploadSuccess(true);
        setUploadIsProcessing(false);
      },
      () => {
        setUploadFailed(true);
        setUploadIsProcessing(false);
      }
    );
  }

  const handleSubmit = (e) => {
    e && e.preventDefault();
    if (titleValue !== '' && submissionType !== 'none') {
      if (submissionType === 'image' && imageValue.size) {
        uploadAsset(imageValue, createPost, (error) => {
          console.log(error);
          setUploadFailed(true);
        });
      } else if (
        (submissionType === 'text' && textValue !== '') ||
        (submissionType === 'video' && videoUrlValue !== '') ||
        (submissionType === 'audio' && audioUrlValue !== '')
      ) {
        createPost();
      } else {
        setUploadFailed(true);
      }
    }
  }

  return (
    <motion.main
      className={`content submit${uploadIsProcessing === true ? ' processing' : ''} container-fluid`}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <HelmetContent
{...props}
        title={`Submit`}
        path={props.location.pathname}
      />
      <div className="submit__intro">
        {submit.introText ? parse(submit.introText) : ''}
      </div>
      {
        uploadIsProcessing === false ?
        <form
          onSubmit={handleSubmit}
          className="submit__form row"
        >
        <div className="submit__intro">
        </div>
          {
            uploadSuccess === true &&
              <div className="submit__form__success">
                <p className="submit__form__success__text">Thank you for uploading!</p>
              </div>
          }
          {
            uploadFailed === true &&
            <div className="submit__form__error">Something went wrong, please try again</div>
            }
            <div className="col-xxs-12">
              <div className="submit__form__item">
                <label className="submit__form__label" htmlFor="title">Title of your upload (this will show in the archive)</label>
                <input
                required
                className="submit__form__input"
                type="text"
                name="title"
                value={titleValue} onChange={(e) => {
                  setTitleValue(e.target.value);
                }}
              />
            </div>
            </div>
            <div className="col-xxs-12 col-md-6">
              <div className="submit__form__item">
                <label className="submit__form__label" htmlFor="name">Your name (optional)</label>
                <input className="submit__form__input" type="text" name="name" value={nameValue} onChange={(e) => (setNameValue(e.target.value))} />
              </div>
            </div>
            <div className="col-xxs-12 col-md-6">
              <div className="submit__form__item">
                <label className="submit__form__label" htmlFor="link">A link to your socials/website (optional)</label>
                <input className="submit__form__input" type="url" name="link" value={linkValue} onChange={(e) => { setLinkValue(e.target.value); }} />
              </div>
            </div>
            <div className="col-xxs-12">
              <SubmissionTypeSelect
                {...props}
                submissionType={submissionType}
                setSubmissionType={setSubmissionType}
                submissionTypes={submissionTypes}
              />
            </div>
            {
              submissionType === 'text' &&
              <SubmissionTextEditor
                {...props}
                textValue={textValue}
                setTextValue={setTextValue}
              />
            }
            {
              submissionType === 'image' &&
              <SubmissionImageUploader
                {...props}
                imageValue={imageValue}
                setImageValue={setImageValue}
              />
            }
            {
              submissionType === 'video' &&
              <div className="col-xxs-12 col-md-6">
                <div className="submit__form__item">
                  <label className="submit__form__label" htmlFor="video URL">Your video URL (please input a Youtube or Vimeo URL)</label>
                  <input
                    className="submit__form__input"
                    type="text"
                    name="video URL"
                    value={videoUrlValue}
                    onChange={(e) => { setVideoUrlValue(e.target.value); }}
                  />
                </div>
              </div>
            }
            {
              submissionType === 'audio' &&
              <div className="col-xxs-12 col-md-6">
                <div className="submit__form__item">
                  <label className="submit__form__label" htmlFor="audio URL">Your audio URL (please input a Soundcloud or Mixcloud URL)</label>
                  <input
                    className="submit__form__input"
                    type="text"
                    name="audio URL"
                    value={audioUrlValue}
                    onChange={(e) => { setAudioUrlValue(e.target.value); }}
                  />
                </div>
              </div>
            }
            {
              (submissionType === 'image' || submissionType === 'video' || submissionType === 'audio') &&
              <div className="col-xxs-12 col-md-6">
                <div className="submit__form__item">
                  <label className="submit__form__label" htmlFor="caption">Caption</label>
                  <input
                    className="submit__form__input"
                    type="text"
                    name="caption"
                    value={captionValue}
                    onChange={(e) => setCaptionValue(e.target.value)}
                  />
                </div>
              </div>
            }
            {
              (
                (submissionType === 'text' && textValue !== '') ||
                (submissionType === 'image' && imageValue.size > 0) ||
                (submissionType === 'video' && videoUrlValue !== '') ||
                (submissionType === 'audio' && audioUrlValue !== '')
              ) &&
              <div className="col-xxs-12 col-md-6">
                <input type="submit" name="submit" className="upload-button" value={'Submit'} />
              </div>
            }
            <br />
        </form>
          :
        <div className="submit__form__processing">
          <p className="submit__form__processing__text">Uploading...</p>
        </div>  
      }
    </motion.main>
  )
}

export default Submit;