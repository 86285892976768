import CookieConsent from 'react-cookie-consent';
import { motion } from 'framer-motion';

const CookiesNotice = (props) => {

  const { handleAcceptCookies } = props;

  return (
    <motion.div
      initial={{ y: 0 }}
      animate={{ y: 0 }}
      exit={{ y: '100%' }}
      transition={{ style: 'linear' }}
      className="cookies-notice__wrapper"
    >
      <CookieConsent
        containerClasses="cookies-notice"
        location="none"
        buttonText="I accept"
        disableButtonStyles
        acceptOnScroll={true}
        hideOnAccept={false}
        disableStyles
        buttonClasses="cookies-notice__option cookies-notice__button"
        buttonWrapperClasses="cookies-notice__options"
        onAccept={handleAcceptCookies}
      >
        <p>By using this site you consent to the use of cookies.</p>
      </CookieConsent>
    </motion.div>
  )
}

export default CookiesNotice;