import PageContentRow from "./PageContentRow";

const PageContent = (props) => {

  const { page } = props;
  const { content } = page;

  if (content && content[0]) {
    return (
      <div className="container-fluid">
        {
          content.map(
            (row, i) => (
              <PageContentRow {...props} row={row} key={i} />
            )
          )
        }
      </div>
    );
  } else {
    return null;
  }
}

export default PageContent;