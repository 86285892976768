import { motion } from 'framer-motion';
import Edition from '../components/EditionThumbnail';
import HelmetContent from '../utils/HelmetContent';

const Editions = (props) => {

  const { editions } = props;

  return (
    <motion.main
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className="editions content"
    >
      <HelmetContent
{...props}
        title={`Editions`}
        path={props.location.pathname}
      />
      <div className="page__inner page__inner">
        <div className="editions__content">
          <div className="container-fluid">
            <ul className="editions row">
              {
                editions &&
                editions[0] &&
                editions.map(
                  (edition, i) => (
                    <Edition edition={edition} key={i} {...props} />
                  )
                )
              }
            </ul>
          </div>
        </div>
      </div>
    </motion.main>
  );
}

export default Editions;