import { useState, useEffect } from 'react';
import ArticleThumbnail from '../ArticleThumbnail';

const PageContentRowArticles = (props) => {

  const { row, selected, articles } = props;
  const { value } = row;
  const [displayedArticles, setDisplayedArticles] = useState([]);

  useEffect(() => {
    const displayedArticlesList = [];
    if (selected === true) {
      if (value && value[0]) {
        for (let item of value) {
          for (let article of articles) {
            if (article._id === item._id) {
              displayedArticlesList.push(article);
            }
          }
        }
      }
    } else {
      const number = parseFloat(value);
      if (number) {
        for (let i = 0; i < number; i++) {
          if (articles[i]) {
            displayedArticlesList.push(articles[i]);
          }
        }
      }
    }
    setDisplayedArticles(displayedArticlesList);
  }, [value, selected, articles]);

  return (
    <ul className="row articles list--unstyled">
      {
        displayedArticles.map(
          (article, index) => (
            <ArticleThumbnail {...props} article={article} key={index} />
          )
        )
      }
    </ul>
  )
}

export default PageContentRowArticles;