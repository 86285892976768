import { useEffect, useState } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import parse from 'html-react-parser';
import PayPalElement from '../components/Shop/PayPalElement';
import LazyloadImage from './LazyloadImage';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const ProductGallery = (props) => {

  const { images } = props;

  const settings = {
          dots: true,
          infinite: true,
          speed: 500,
          slidesToShow: 1,
          slidesToScroll: 1,
          variableWidth: false
        }

  return (
    <div className="product__gallery">
      <Slider {...settings}>
        {
          images.map(
            (image, i) => (
              <div
                className={`product__gallery__slide`}
                key={i}
              >
                <LazyloadImage image={image} />
              </div>
            )
          )
        }
      </Slider>
    </div>
  )
}

const Product = (props) => {

  const { product, shop } = props;
  const [shipping, setShipping] = useState('');
  const [shippingCost, setShippingCost] = useState(0);
  const [paymentIsActive, setPaymentIsActive] = useState(false);
  const [paymentIsComplete, setPaymentIsComplete] = useState(false);
  const [descriptionIsExpanded, setDescriptionIsExpanded] = useState(false);
  const { shippingUK, shippingEU, shippingWorldwide, collectOption } = product;
  const { defaultShippingUK, defaultShippingEU, defaultShippingWorldwide } = shop;

  useEffect(() => {
    if (shipping !== '') {
      if (shipping === 'UK') {
        if (shippingUK && shippingUK !== '') {
          setShippingCost(parseFloat(shippingUK));
        } else if (defaultShippingUK && defaultShippingUK !== '') {
          setShippingCost(parseFloat(defaultShippingUK));
        } else {
          setShippingCost(0);
        }
      } else if (shipping === 'EU') {
        if (shippingEU && shippingEU !== '') {
          setShippingCost(parseFloat(shippingEU));
        } else if (defaultShippingEU && defaultShippingEU !== '') {
          setShippingCost(parseFloat(defaultShippingEU));
        } else {
          setShippingCost(0);
        }
      } else if (shipping === 'Worldwide') {
        if (shippingWorldwide && shippingWorldwide !== '') {
          setShippingCost(parseFloat(shippingWorldwide));
        } else if (defaultShippingWorldwide && defaultShippingWorldwide !== '') {
          setShippingCost(parseFloat(defaultShippingWorldwide));
        } else {
          setShippingCost(0);
        }
      } else if (shipping === 'collect') {
        setShippingCost(0);
      }
    }
  }, [shipping, defaultShippingUK, defaultShippingEU, defaultShippingWorldwide, shippingUK, shippingEU, shippingWorldwide]);

  return (
    <li className="product col-xxs-12">
      <div className="product__inner row">
        <div className="col-xxs-12 col-xs-11 offset-xs-1 col-sm-8 offset-sm-2 col-md-5 offset-md-1 order-md-0">
          {
            product.images && product.images[0] &&
            <ProductGallery images={product.images} />
          }
        </div>
        <div className="col-xxs-12 col-xs-11 offset-xs-1 col-sm-8 offset-sm-2 col-md-5 offset-md-0 order-md-2 product__info">
          <div className="row">
            <div className="col-md-6">
              <h3 className="product__title">{product.title}</h3>
            </div>
            <div className="col-md-6">
              <p className="product__price">£{product.price}</p>
            </div>
          </div>
          {
            paymentIsActive === false && paymentIsComplete === false &&
            (product.stock > 0 || product.manageStock !== true) &&
            <button
              className="product__button--buy button--plain"
              onClick={() => { setPaymentIsActive(true); }}
            >Buy</button>
          }
          {
            product.description &&
            <div className={`product__description${descriptionIsExpanded === false ? ' collapsed' : ''}`}>
              {parse(product.description)}
              {
                descriptionIsExpanded === false &&
                <button
                  className="show-more-button"
                  onClick={() => setDescriptionIsExpanded(true)}
                >Read more</button>
              }
            </div>
          }
          {
            product.stock <= 0 && product.manageStock === true &&
            <p>Out of stock</p>
          }
          {
            paymentIsActive === true &&
            <div className="shipping-options__wrapper">
              <p>Select location for shipping</p>
              <div className="shipping-options">
                <button
                  className={`shipping-option shipping-option--UK${shipping === 'UK' ? ' active' : ''}`}
                  onClick={() => setShipping('UK')}
                  aria-label="Select UK shipping"
                  >UK ({shippingUK && shippingUK !== '' ? '£' + shippingUK : defaultShippingUK && defaultShippingUK !== '' ? '£' + defaultShippingUK : 'free!'})</button>
                <button
                  className={`shipping-option shipping-option--EU${shipping === 'EU' ? ' active' : ''}`}
                  onClick={() => setShipping('EU')}
                  aria-label="Select EU Shipping"
                >EU ({shippingEU && shippingEU !== '' ? '£' + shippingEU : defaultShippingEU && defaultShippingEU !== '' ? '£' + defaultShippingEU : 'free!'})</button>
                <button
                  className={`shipping-option shipping-option--Worldwide${shipping === 'Worldwide' ? ' active' : ''}`}
                  onClick={() => setShipping('Worldwide')}
                  aria-label="Select shipping to the rest of the world"
                >The rest of the world ({shippingWorldwide && shippingWorldwide !== '' ? '£' + shippingWorldwide : defaultShippingWorldwide && defaultShippingWorldwide !== '' ? '£' + defaultShippingWorldwide : 'free!'})</button>
                {
                  collectOption === true &&
                  <button
                    className={`shipping-option shipping-option--collect${shipping === 'collect' ? ' active' : ''}`}
                    onClick={() => setShipping('collect')}
                    aria-label="Collect the item (London) instead of having it shipped"
                  >Collect item (London pick up) (Free)</button>
                }
              </div>
            </div>
          }
          {
            paymentIsActive === true && product.price && shipping !== '' &&
            <p className="total-cost-calculator">Total with shipping £{parseFloat(product.price) + parseFloat(shippingCost)}</p>
          }
          <AnimatePresence mode='wait'>
            {
              paymentIsActive === true && product.price && shipping !== '' &&
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{opacity: 0 }}
                key={shippingCost}
              >
                <PayPalElement
                  {...props}
                  shipping={shipping}
                  product={product}
                  shippingCost={shippingCost}
                  paymentIsActive={paymentIsActive}
                  setPaymentIsActive={setPaymentIsActive}
                  paymentIsComplete={paymentIsComplete}
                  setPaymentIsComplete={setPaymentIsComplete}
                />
              </motion.div>
            }
          </AnimatePresence>
        </div>
      </div>
    </li>
  );
}

export default Product;