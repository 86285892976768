import { useState } from 'react';

const SubmissionTypeSelect = (props) => {

  const { submissionType, setSubmissionType, submissionTypes } = props;
  const [selectIsFocused, setSelectIsFocused] = useState(false);

  return (
    <div className="submit__form__item">
      <label className="submit__form__label" htmlFor="email">Please select the type of content you'd like to submit</label>
      <div
        className="submit__form__select--spoof"
      >
        <button
          className={`${selectIsFocused === true ? 'active ' : ''}submit__form__select__button`}
          onClick={() => { setSelectIsFocused(!selectIsFocused); }}
        >{submissionType}</button>
        {
          selectIsFocused === true &&
          <ul className="submit__form__select__options">
            {
              submissionTypes &&
              submissionTypes[0] &&
              submissionTypes.map(
                (item, i) => (
                  <li
                    className="submit__form__select__option"
                    key={i}
                  >
                    <button
                      className="submit__form__select__option__button"
                      onClick={() => {
                        setSubmissionType(item);
                        setSelectIsFocused(false);
                      }}
                    >
                      {item}
                    </button>
                  </li>
                )
              )
            }
          </ul>
        }
      </div>
    </div>
  );
}

export default SubmissionTypeSelect;
