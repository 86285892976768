import { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import ArticleThumbnail from '../components/ArticleThumbnail';
import ArchiveSearch from '../components/ArchiveSearch';
import HelmetContent from '../utils/HelmetContent';

const Archive = (props) => {

  const { articles, windowWidth } = props;
  const [archiveSearchTerm, setArchiveSearchTerm] = useState('');
  const [allArticles, setAllArticles] = useState(articles);
  const [columnCount, setColumnCount] = useState(3);

  useEffect(() => {
    // col-xxs-12 col-xs-6 col-sm-4 col-lg-3
    if (windowWidth < 500) {
      setColumnCount(1);
    } else if (windowWidth < 768) {
      setColumnCount(2);
    } else if (windowWidth < 1500) {
      setColumnCount(3);
    } else {
      setColumnCount(4);
    }
  }, [windowWidth]);

  useEffect(() => {
    let allArticlesArray = [];
    
    if (archiveSearchTerm && archiveSearchTerm !== '') {
      for (let article of articles) {
        let articleIsInArray = false;
        if (article.title.toLowerCase().indexOf(archiveSearchTerm.toLowerCase()) > -1) {
          articleIsInArray = true;
        }
        if (article.tags) {
          for (let tag of article.tags) {
            if (tag.display) {
              if (tag.display.toLowerCase().indexOf(archiveSearchTerm.toLowerCase()) > -1) {
                articleIsInArray = true;
              }
            }
          }
        }
        if (articleIsInArray === true) {
          allArticlesArray.push(article);
        }
      }
    } else {
      if (archiveSearchTerm === '') {
        allArticlesArray = [...articles];
      }
    }

    setAllArticles(allArticlesArray);
  }, [archiveSearchTerm, articles]);

  return (
    <motion.main
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className={`content`}
    >
      <HelmetContent
        {...props}
        title={`Archive`}
        path={`/archive`}
      />
      <div className="container-fluid">
        <div className="row">
          <ArchiveSearch
            {...props}
            archiveSearchTerm={archiveSearchTerm}
            setArchiveSearchTerm={setArchiveSearchTerm}
          />
          {
            Array(columnCount).fill('').map(
              (column, i) => (
                <div className="col-xxs-12 col-xs-6 col-sm-4 col-lg-3" key={i}>
                  {
                    allArticles.map(
                      (article, index) => (
                        index % columnCount === i &&
                        <ArticleThumbnail
                          {...props}
                          article={article}
                          key={index}
                          articlePage={true}
                        />
                      )
                    )
                  }
                </div>
              )
            )
          }
        </div>
      </div>
    </motion.main>
  )
}

export default Archive;