import parse from 'html-react-parser';
import ReactMarkdown from 'react-markdown';
import PageContentRowLargeImage from '../Page/PageContentRowLargeImage';
import VideoPlayer from '../VideoPlayer';

const ArticleLayoutVideo = (props) => {

  const { article } = props;
  const { title, subtitle, excerpt, videoEmbed, audioEmbed, content, caption, image } = article;

  return (
    <div className="wrapper">
      <div className="container-fluid">
        <div className="row">
          <div className="col-xxs-12">
            {
              caption &&
              <p>{caption}</p>
            }
          </div>
          {
            videoEmbed &&
            <div className="col-xxs-12 article__col--video no-padding">
              <VideoPlayer url={videoEmbed} /> 
            </div>
          }
          <div className="col-xxs-12">
            {
              audioEmbed &&
              <VideoPlayer url={audioEmbed} />
              
            }
            {
              image && image.path &&
              <PageContentRowLargeImage value={image} />
              
            }
          </div>
          <div className="col-xxs-12">
            <div className="article__title__wrapper">
              <h2 className="article-thumbnail__title">{title}</h2>
              <h3 className="article-thumbnail__subtitle">{subtitle}</h3>
            </div>
            {
              excerpt &&
              <div className="featured__intro">
                <ReactMarkdown>{excerpt}</ReactMarkdown>
              </div>
              }
              {
                content && content !== '' &&
                <div className="text__wrapper article__text__wrapper">
                  {parse(content)}
                </div>
              }
          </div>
        </div>
      </div>
    </div>
  )
}

export default ArticleLayoutVideo;