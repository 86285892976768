import { useState, useEffect } from 'react';
import Page from './Page';

const Homepage = (props) => {

  const { homepage, pages } = props;
  const [page, setPage] = useState({});

  useEffect(() => {
    if (homepage.homepage) {
      const pageId = homepage.homepage._id;
      for (let pageObject of pages) {
        if (pageObject._id === pageId) {
          setPage(pageObject);
        }
      }
    }
  }, [homepage, pages]);

  return (
    <Page {...props} page={page} />
  )
}

export default Homepage;