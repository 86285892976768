import parse from 'html-react-parser';
import ReactMarkdown from 'react-markdown';
import Slider from 'react-slick';
import LazyloadImage from '../LazyloadImage';

const ArticleLayoutArticle = (props) => {

  const { article } = props;
  const { title, subtitle, excerpt, image, gallery, content } = article;

  const settings = {
          dots: true,
          infinite: true,
          speed: 500,
          slidesToShow: 1,
          slidesToScroll: 1,
          variableWidth: false
        }

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-xxs-12 col-sm-6">
          {
            gallery && gallery[0] ?
              <Slider {...settings}>
                {
                  image?.path && image.path !== '' &&
                  <div className={`article__gallery__slide`}>
                    <LazyloadImage image={image} />
                  </div>
                }
                {
                  gallery.map(
                    (image, i) => (
                      <div
                        className={`article__gallery__slide`}
                        key={i}
                      >
                        <LazyloadImage image={image} />
                      </div>
                    )
                  )
                }
              </Slider>
              :
            image?.path && image.path !== '' &&
            <LazyloadImage image={image} />
          }
        </div>
        <div className="col-xxs-12 col-sm-6">
          <div className="article__title__wrapper">
            <h2 className="article-thumbnail__title">{title}</h2>
            <h3 className="article-thumbnail__subtitle">{subtitle}</h3>
          </div>
          {
            excerpt &&
            <div className="featured__intro">
              <ReactMarkdown>{excerpt}</ReactMarkdown>
            </div>
          }
        </div>
        <div className="col-xxs-12">
          {
            content && content !== '' &&
            <div className="text__wrapper article__text__wrapper">
              {parse(content)}
            </div>
          }
        </div>
      </div>
    </div>
  )
}

export default ArticleLayoutArticle;