import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

const MenuItem = (props) => {

  const { item, pathname, searchIsActive, setSearchIsActive, setMenuIsActive, pages, className } = props;
  const { field, value } = item;
  const [path, setPath] = useState('');
  const [text, setText] = useState('');

  useEffect(() => {
    if (field.name !== 'search') {
      if (field.name === 'page' && value._id && pages) {
        for (let page of pages) {
          if (page._id === value._id) {
            setPath(`/page/${page.name_slug}`);
            setText(page.name);
          }
        }
      } else if (field.name === 'home') {
        setPath(`/`);
        setText(value);
      } else if (field.name === 'text') {
        setText(value);
      } else if (field.name === 'externalLink') {
        setText(value.text);
        setPath(value.url);
      } else {
        setPath(`/${field.name.toLowerCase()}`);
        setText(value);
      }
    }
  }, [field, value, pages]);

  if (field.name === 'search') {
    return (
      <button
        to="/search"
        onClick={() => {
          setSearchIsActive(!searchIsActive);
          setMenuIsActive(false);
        }}
        className={`${className ? className : ''}${searchIsActive === true ? ' active' : ''}`}
      >Search</button>
    );
  } else {
    if (field.name === 'externalLink') {
      return (
        <a
          href={path}
          className={className ? className : null}
          rel="noreferrer"
          target="_blank"
        >{text}</a>
      )
    } else if (path !== '' && text !== '') {
      return (
        <Link
          to={path}
          className={`${className ? className : ''}${pathname === path ? ' active' : ''}`}
        >{text}</Link>
      );
    } else  if (field.name === 'text') {
      return (
        <p
          className={`${className ? className : ''} plain-text`}
        >{text}</p>
      );
    } else if (field.name === 'spacer') {
      return (
        <div className={`${className ? className : ''} spacer`} />
      );
    } else {
      return null;
    }
  }
};

export default MenuItem;