import { Link } from 'react-router-dom';
import ReactMarkdown from 'react-markdown';
import LazyloadImage from './LazyloadImage';

const ArticleThumbnail = (props) => {

  const { article, articlePage } = props;
  const { title, title_slug, subtitle, image, excerpt, layout } = article;

    return (
      <div className={`article-thumbnail${layout === 'contribution' ? ' contribution' : ''}${articlePage !== true ? ' col-xxs-12 col-xs-6 col-sm-4 col-lg-3' : ''}`}>
        {
          image?.path && image.path !== '' &&
          <Link
            to={`/article/${title_slug}`}
            className="article-thumbnail__link--image"
          >
            <LazyloadImage image={image} />
          </Link>
        }
        <Link
          to={`/article/${title_slug}`}
          className="article-thumbnail__inner"
        >
          <h2 className="article-thumbnail__title">
            {title}
          </h2>
          <h3 className="article-thumbnail__subtitle">
            {subtitle}
          </h3>
        </Link>
        {
          excerpt &&
          <div className="article-thumbnail__intro">
            <ReactMarkdown>{excerpt}</ReactMarkdown>
          </div>
        }
      </div>
    );
}

export default ArticleThumbnail;