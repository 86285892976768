import { useState } from 'react';

const SubmissionImageUploader = (props) => {

  const { setImageValue } = props;
  const [imagePreviewPath, setImagePreviewPath] = useState('');

  const processImage = (event) => {
    const img = document.createElement('img');

    const handleImageLoad = () => {
      const canvas = document.createElement('canvas');
      canvas.width = 2000;
      canvas.height = Math.floor(2000 / img.naturalWidth * img.naturalHeight);
      const ctx = canvas.getContext('2d');
      ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
      
      const dataURL = canvas.toDataURL('image/jpeg', 0.3);
      setImagePreviewPath(dataURL);
      
      canvas.toBlob((blob) => {
        const file = new File([blob], `image-at-${new Date().getTime()}.jpg`, { type: "image/jpeg" });
        setImageValue(file);
      }, 'image/jpeg', 0.3);
    }

    img.addEventListener('load', handleImageLoad);

    if (event) {
      if (event.target?.files && event.target.files.length > 0) {
        img.src = URL.createObjectURL(event.target.files[0]);
      }
    }

    return () => {
      img.removeEventListener('load', handleImageLoad);
    }
  }

  return (
    <div className="col-xxs-12 col-md-6">
      <div className="submit__form__item">
        {
          imagePreviewPath !== '' &&
          <img src={imagePreviewPath} className="submit__form__image-preview" alt="preview" />
        }
        <label className="submit__form__label" htmlFor="name">Your image</label>
        <input
          className="submit__form__file"
          accept="image/png, image/jpg, image/jpeg, image/gif"
          type="file"
          name="image"
          id="uploadImage"
          onChange={processImage}
        />
      </div>
    </div>
  )
};

export default SubmissionImageUploader;