import { motion } from 'framer-motion';
import parse from 'html-react-parser';
import EditionViewer from '../components/EditionViewer';
import HelmetContent from '../utils/HelmetContent';

const Edition = (props) => {

  const { edition } = props;
  const { title, information } = edition;

  return (
    <motion.main
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className={`content`}
    >
      <HelmetContent
{...props}
        title={edition.title}
        image={edition.imageFront ? edition.imageFront.path : null}
        description={edition.excerpt}
        path={props.location.pathname}
      />
      <div className="container-fluid">
        <div className="row">
          <div className="col-xxs-12 col-sm-6">
            <EditionViewer {...props} />
          </div>
          <div className="col-xxs-12 col-sm-6">
            <h3 className="edition__title">{title}</h3>
            <div className="edition__info">
              {information ? parse(information) : ''}
            </div>
          </div>
        </div>
      </div>
    </motion.main>
  )
}

export default Edition;