import { PayPalScriptProvider, PayPalButtons } from '@paypal/react-paypal-js';
import { saveCollectionEntry } from '../../utils/cockpit';

const PayPalElement = (props) => {

  const { product, setPaymentIsActive, setPaymentIsComplete, shop, shipping, shippingCost } = props;
  const { price, manageStock } = product;
  const { paypalClientId } = shop;

  return (
    <PayPalScriptProvider
      options={{
        "client-id": paypalClientId,
        "currency": "GBP"
      }}
    >
      <PayPalButtons
        createOrder={(data, actions) => {
          return actions.order.create({
            purchase_units: [
              {
                amount: {
                  value: parseFloat(price) + parseFloat(shippingCost),
                }
              },
            ],
          });
        }}
        onApprove={(data, actions) => {
          return actions.order.capture().then((details) => {
            const { payer, create_time, id, purchase_units } = details;
            const { address } = purchase_units[0].shipping;
            const { address_line_1, admin_area_1, admin_area_2, country_code, postal_code } = address;
            const { name, email_address, payer_id } = payer;
            
            setPaymentIsActive(false);
            setPaymentIsComplete(true);
            if (manageStock === true) {
              const data = {
                ...product,
                stock: product.stock - 1
              }
              saveCollectionEntry('products', data);
            }

            const entryData = {
              name: name.given_name + ' ' + name.surname,
              email: email_address,
              time: create_time,
              purchaseId: id,
              payerId: payer_id,
              shippingType: shipping,
              shippingCost: shippingCost,
              paymentSuccessful: true,
              product: {
                ...product,
                display: product.title
              },
              price: product.price,
              address: address_line_1 + ', ' + admin_area_1 + ', ' + admin_area_2 + ', ' + country_code + ', ' + postal_code
            }
            
            saveCollectionEntry('orders', entryData);

            setTimeout(() => {
              setPaymentIsComplete(false);
            }, 3000);
          });
        }}
      />
    </PayPalScriptProvider>
  );
}

export default PayPalElement;