import { motion } from 'framer-motion';

const InitialiseAnimation = (props) => {

  const { windowHeight } = props;

  return (
    <motion.div
      initial={{ options: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ delay: 0.4 }}
      className="initialise-animation"
      style={{
        height: windowHeight + 'px'
      }}
    />
  )
};

export default InitialiseAnimation;